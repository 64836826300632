/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Header from "./header"
import "./layout.css"

const Wrapper = styled.section`
  h1 {
    @media (min-width: 768px) {
      margin-top: 100px;
    }
    text-align: center;
  }
  h2 {
    font-size: 18px;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 22px;
      text-align: right;
      position: fixed;
      z-index: 110;
      top: 0px;
      width: 20%;
      background: lightgrey;
      right: 0;
    }
  }
  margin: 0 auto;
`

const BlogGrid = styled.section`
  width: 300px;
  @media (min-width: 768px) {
    width: 768px;
  }
  @media (min-width: 1080px) {
    width: 1080px;
  }
  margin: 50px auto;
  div.blog-single {
    border: 1px solid #000000;
    padding: 25px;
    margin: 25px 0;
  }
  h3 {
    font-size: 12px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: 768px) {
    h3 {
      font-size: 22px;
    }
    p {
      font-size: 22px;
      line-height: 28px;
    }
  }
`

//Force Push

const LayoutBlogList = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleBlogListQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Wrapper>
        <h1>Our Kindergarten Blog</h1>
        <BlogGrid>
          <div className="outer">{children}</div>
        </BlogGrid>
        <footer></footer>
      </Wrapper>
    </>
  )
}

LayoutBlogList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutBlogList
