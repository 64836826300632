import React from "react"
import styled from "styled-components"

export const Pagination = styled.div`
  //position: fixed;
  right: 0;
  z-index: 110;
  bottom: 0;
  background: #80808061;
  @media (min-width: 768px) {
    padding: 20px 0;
    font-size: 22px;
    text-align: center;
    //width: 400px;
  }
  a {
    padding: 10px;
    color: #000000;
    text-decoration: none;
    display: inline-block;
    &:hover {
      color: #ffffff;
    }
  }
  [aria-current="page"] {
    color: #ffffff;
  }
`
